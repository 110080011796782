<template>
  <div class="main">
    <div class="box-top"></div>
    <div class="box">
      <div class="box-m"></div>

      <div class="sc-width main-box">
        <div class="cards">
          <div>
            <div class="search-top-menu">
              <div
                class="item"
                v-for="(val, index) in checkValueArr"
                :key="index"
              >
                <div
                  :class="[
                    'value',
                    'search-button',
                    { 'value-active': val.checkValue == checkValue }
                  ]"
                  @click="changeCheckValue(val.checkValue)"
                >
                  {{ val.label }}
                </div>
                <div class="line" v-if="val.checkValue == checkValue"></div>
              </div>
            </div>
            <div class="border"></div>
            <div class="search">
              <div class="left">
                <div class="search-icon">
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.5913 24.228L19.8912 17.7994C21.5013 15.9448 22.3042 13.566 22.1328 11.1578C21.9615 8.7497 20.8292 6.49765 18.9714 4.87017C17.1136 3.24268 14.6734 2.36507 12.1585 2.4199C9.64347 2.47473 7.24733 3.45778 5.46851 5.16455C3.68969 6.87132 2.66514 9.17039 2.60799 11.5835C2.55085 13.9966 3.46551 16.338 5.1617 18.1205C6.85789 19.903 9.20502 20.9895 11.7148 21.1539C14.2246 21.3182 16.7038 20.5479 18.6367 19.003L25.3368 25.4317L26.5913 24.228ZM4.41157 11.8117C4.41157 10.2964 4.87986 8.8152 5.75723 7.55531C6.6346 6.29542 7.88164 5.31346 9.34065 4.7336C10.7997 4.15374 12.4051 4.00202 13.954 4.29763C15.5029 4.59324 16.9256 5.3229 18.0423 6.39435C19.159 7.46579 19.9194 8.8309 20.2275 10.317C20.5356 11.8032 20.3775 13.3436 19.7731 14.7435C19.1688 16.1434 18.1454 17.3399 16.8323 18.1818C15.5192 19.0236 13.9755 19.4729 12.3963 19.4729C10.2793 19.4707 8.24973 18.6628 6.75282 17.2265C5.25591 15.7902 4.41392 13.8429 4.41157 11.8117Z"
                      fill="#545454"
                    />
                  </svg>
                </div>
                <div class="search-input">
                  <input
                    type="text"
                    placeholder="Search..."
                    @keydown.enter="getFavorites"
                    v-model="filter.search"
                  />
                </div>
              </div>
            </div>
            <div class="list-table">
              <div class="title">
                <div class="item attendee font-medium">Image</div>
                <div class="item size font-medium">Name</div>
                <div class="item size font-medium status-col">Type</div>
                <div class="item date font-medium">Date</div>
                <div class="item date font-medium">Action</div>
              </div>
              <div class="content">
                <div class="loading-pic" v-if="loading">
                  <img src="@/assets/loading.gif" />
                </div>
                <div
                  v-for="(item, index) in list"
                  :key="index"
                  :class="['item', { active: activeIndex === item.id }]"
                  @mouseover="companyMouseOver(item.id)"
                  @mouseleave="companyMouseLeave()"
                >
                  <div class="top search-button">
                    <div class="attendee font-medium" @click="view(item)">
                      <template v-if="checkValue == 0">
                        <img :src="item.avatar[0].url" alt="" class="logo" />
                      </template>
                      <template v-else>
                        <img :src="item.avatar.url" alt="" class="logo" />
                      </template>
                    </div>
                    <div class="attendee font-medium name" @click="view(item)">
                      <template v-if="checkValue == 0">{{
                        item.marketing_name || "--"
                      }}</template>
                      <template v-else>{{
                        item.company_name || "--"
                      }}</template>
                    </div>
                    <div class="status-col font-medium" @click="view(item)">
                      <div
                        class="status status-progress"
                        v-if="checkValue == 1"
                      >
                        Storefront
                      </div>
                      <div
                        class="status status-completed"
                        v-if="checkValue == 0"
                      >
                        Product
                      </div>
                    </div>
                    <div class="date font-medium" @click="view(item)">
                      {{ item.pivot.created_at | moment }}
                    </div>
                    <div class="date font-medium">
                      <span @click="deleteFavorite(item.id)">
                        <svg
                          width="21"
                          height="19"
                          viewBox="0 0 21 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.7578 3.7172C19.4437 2.98979 18.9907 2.33062 18.4242 1.77658C17.8573 1.22089 17.189 0.779292 16.4555 0.475798C15.6949 0.159844 14.8791 -0.00187919 14.0555 1.64739e-05C12.9 1.64739e-05 11.7727 0.316423 10.793 0.914079C10.5586 1.05705 10.3359 1.21408 10.125 1.38517C9.91406 1.21408 9.69141 1.05705 9.45703 0.914079C8.47734 0.316423 7.35 1.64739e-05 6.19453 1.64739e-05C5.3625 1.64739e-05 4.55625 0.159392 3.79453 0.475798C3.05859 0.780485 2.39531 1.21877 1.82578 1.77658C1.25859 2.32999 0.805495 2.98932 0.492188 3.7172C0.166406 4.47424 0 5.27814 0 6.10548C0 6.88595 0.159375 7.69923 0.475781 8.52658C0.740625 9.21799 1.12031 9.93517 1.60547 10.6594C2.37422 11.8055 3.43125 13.0008 4.74375 14.2125C6.91875 16.2211 9.07266 17.6086 9.16406 17.6649L9.71953 18.0211C9.96563 18.1781 10.282 18.1781 10.5281 18.0211L11.0836 17.6649C11.175 17.6063 13.3266 16.2211 15.5039 14.2125C16.8164 13.0008 17.8734 11.8055 18.6422 10.6594C19.1273 9.93517 19.5094 9.21799 19.7719 8.52658C20.0883 7.69923 20.2477 6.88595 20.2477 6.10548C20.25 5.27814 20.0836 4.47424 19.7578 3.7172Z"
                            fill="#F24E1E"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="empty" v-if="list.length === 0 && !loading">
              No Data
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box-m"></div>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import moment from "moment";

const { _queryFavorite, _deleteFavorite } = api;

export default {
  name: "favorites",
  computed: {
    ...mapState("user", ["role"])
  },
  components: {},
  data() {
    return {
      loginPop: false,
      confLoading: false,
      showInquire: false,
      inquireForm: {
        product_name: "-"
      },
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      curItem: {},
      modType: "card",
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,
      cateList: [],
      countryList: [],
      filter: {
        search: null
      },
      prices: ["CIF", "FOB", "Delivered", "Others"],
      price_type: "",
      justShowInquire: false,
      activeIndex: 0,
      showInstruction: false,
      allNum: 0,
      progressNum: 0,
      completedNum: 0,
      expiredNum: 0,
      checkValue: 1,
      checkValueArr: [
        { label: "Storefronts", checkValue: 1, value: 1 },
        { label: "Products", checkValue: 0, value: 0 }
      ],
      inquiry: {},
      isShowMenu: 0,
      isShowClose: false,
      closeInquiry: {},
      closeLoading: false,
      isEditInquiry: false
    };
  },
  mounted() {
    this.getFavorites();
  },
  filters: {
    moment(val) {
      return moment
        .utc(val)
        .local()
        .fromNow();
    }
  },
  methods: {
    view(item) {
      if (1 == this.checkValue) {
        this.$router.push(`/company/${item.id}`);
      } else {
        this.$router.push(`/products/${item.id}`);
      }
    },
    deleteFavorite(id) {
      _deleteFavorite({ id: id, type: this.checkValue });
      this.getFavorites();
    },
    showClose(item) {
      this.isShowClose = true;
      this.closeInquiry = item;
    },
    changeCheckValue(val) {
      this.checkValue = val;
      this.filter.status = val;
      this.filter.search = null;
      this.getFavorites();
    },
    companyMouseOver(e) {
      this.activeIndex = e;
    },
    companyMouseLeave() {
      this.activeIndex = null;
    },
    clearSearch() {
      this.page = 1;
      this.curBar = "";
      this.filter.product_name = "";
      this.getFavorites();
    },

    handleSearch() {
      this.page = 1;
      this.getFavorites();
    },

    pageChange(page) {
      this.page = page;
      this.getFavorites();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.getFavorites();
    },
    getFavorites() {
      this.loading = true;
      this.list = [];
      _queryFavorite({
        ...this.filter,
        type: this.checkValue
      })
        .then(response => {
          const { data } = response;
          this.list = data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.sc-width {
  /*background: #ffffff;*/
}
.box {
  margin: 126px auto 0 auto;
  background: #f2f2f2;
  .main-box {
    background: #fff;
    border-radius: 15px;
  }
}
.box-m {
  height: 24px;
  background: #f2f2f2;
}
.main-title-box {
  /*display: flex;*/
  align-items: center;
  justify-content: space-between;
  /*background: #fff;*/
  padding: 40px;
  /*border-bottom: 1px solid #ccc;*/
  margin: 0 auto;
  /*padding-top: 65px;*/
  padding-left: 0;
  padding-right: 0;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .main-title {
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
  .exhibitor-sourcing {
    display: flex;
    justify-content: space-between;
  }
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .my-sourcing {
    /*width: 200px;*/
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary */

    color: #f57f45;
  }
  .add-sourcing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    width: 241px;
    height: 38px;
    background: #f57f45;
    border-radius: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #ffffff;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }

  .first {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    margin-top: 22px;
    .user {
    }
    .view {
      color: #f57f45;
      margin-left: 5px;
    }
  }
  .instruction {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    .step-item {
      .step-top {
        display: flex;
        .icon {
        }
        .word {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;

          /* Gray 2 */

          color: #ffffff;
          margin-left: 12px;
          position: relative;
          bottom: 2px;
        }
      }
      .pic {
        margin-top: 10px;
      }
      .first-pic {
        margin-top: 40px;
      }
    }
    .step-line {
    }
  }
  .hide-instruction {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}

.search-box {
  display: flex;
  align-items: center;
  /*border-radius: 50px;*/
  height: 35px;
  overflow: hidden;
  width: 45%;
  margin: 20px;
  .input-box {
    position: relative;
    flex: 1;
    height: 100%;
    background: #fff;
    line-height: initial;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*border: 1px solid #dfdfdf;*/
    /*border-right: none;*/
    /*border-top-left-radius: 40px;*/
    /*border-bottom-left-radius: 40px;*/
    border: 1px solid #ef7f21;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border-radius: 5px;
    /*display: flex;*/
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
    margin-left: 20px;
    background: #ef7f21;
    font-size: 18px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    /*padding-top: 9px;*/
    width: 120px;
    height: 35px;
  }
  .my-sourcing {
    width: 200px;
    height: 30px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #444;
    font-size: 12px;
    text-align: right;
    .des {
      display: block;
    }
    .num {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// /deep/ .list-table .ivu-table-body .ivu-btn {
//   color: @primary-color;
//   border: 1px solid @primary-color;
// }

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  .left {
    display: flex;
    .search-input {
      width: 400px;
    }
  }
}
.main {
  background: #f2f2f2;
  .box-top {
    height: 27px;
  }
}
.cards {
  .table-title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #000000;
    margin-bottom: 36px;
  }
}
.box {
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    margin-top: 206px;
    .empty {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cards {
      min-height: 300px;
      position: relative;

      .list-table {
        list-style: none;
        li {
          margin-bottom: 10px;
          background-color: #fff;
          border: 1px solid #f2f2f2;
          box-shadow: 0 0 4px #f8f8f8;
          .header {
            padding: 10px;
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            .wrap1 {
              min-width: 80%;
              cursor: pointer;
              text-align: left;
              .title {
                font-size: 14px;
              }
            }
            .wrap2 {
              text-align: right;
              .status {
                display: inline-block;
                background-color: #ddd;
                padding: 2px 8px;
                margin-bottom: 4px;
                &.pend {
                  background-color: #ddd;
                  color: #333;
                }
                &.acc {
                  background-color: #d5ffd2;
                  color: #418d3a;
                }
                &.rej {
                  background-color: #ffddd9;
                  color: #c04c3a;
                }
              }
              .time {
                color: #888;
              }
            }
          }
          .content {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #f2f2f2;
            .con {
              padding: 10px;
              .tit {
                color: #666;
                padding-bottom: 6px;
              }
              .tip {
                font-size: 12px;
              }
              .doc {
                color: #ff6600;
                a {
                  color: #ff6600;
                }
              }
            }
            .c1 {
              cursor: pointer;
            }
            .c1,
            .c2,
            .c3 {
              flex: 0 0 20%;
            }
            .c4 {
              flex: 0 0 40%;
            }
          }
        }
        .footer {
          padding: 10px;
          text-align: right;
          .numb {
            text-align: left;
            padding: 4px 10px;
            display: inline-block;
            background-color: #ffe7d6;
          }
        }
      }
      .page {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
      }

      .ivu-poptip-body {
        h4 {
          font-size: 12px;
          text-align: left;
          color: #444;
          padding: 4px 0;
        }
        .tags {
          flex-wrap: wrap;
          display: flex;
          p {
            text-align: left;
            color: #999;
            flex: 0 0 50%;
            padding: 4px 0;
          }
        }
      }
    }
  }
  .list-table {
    .title {
      display: flex;
      height: 42px;
      background: #f2f2f2;
      border-radius: 5px;
      justify-content: space-between;
      font-size: 18px;

      font-weight: 500;
      color: #757575;
      line-height: 19px;
      .item {
        font-size: 16px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 40px;
      }
      .product {
        width: 400px;
      }
      .attendee {
        width: 300px;
        .logo {
          height: 50px;
        }
      }
      .size {
        width: 300px;
      }
      .status-col {
        width: 150px;
      }
      .date {
        width: 200px;
      }
      .document {
        width: 300px;
      }
    }
    .content {
      /*display: flex;*/
      padding-bottom: 25px;
      .item {
        height: 60px;
        /*padding:0 30px;*/
        .top {
          display: flex;
          height: 60px;
          justify-content: space-between;
          border-bottom: 1px solid #ececec;
          div {
            display: flex;
            justify-content: left;
            align-items: center;
          }
          /*padding-top: 30px;*/
          /*div {*/
          /*  font-size: 16px;*/
          /*  */
          /*  font-weight: 500;*/
          /*  color: #323538;*/
          /*  line-height: 19px;*/
          /*  padding-left: 20px;*/
          /*}*/
          .size {
            padding-left: 40px;
          }
          .status-col {
            width: 150px;
            padding-left: 40px;
          }
          .have-count {
            color: #f57f45;
            font-weight: 600;
          }
          .product {
            width: 400px;
            .main-name {
            }
            .by-name {
              font-size: 12px;

              font-weight: 400;
              color: #999999;
              line-height: 19px;
            }
          }
          .attendee {
            width: 300px;
            padding-left: 40px;
            .logo {
              height: 50px;
            }
          }
          .name {
            color: #ef842d;
            font-weight: 600;
          }
          .size {
            width: 300px;
            padding-left: 40px;
          }
          .date {
            width: 200px;
            padding-left: 40px;
          }
          .document {
            width: 300px;
          }
        }
        .divided {
          /*width: 1440px;*/
          height: 1px;
          background: #cccccc;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
      .active {
        background: #f2f0f9;
      }
    }
  }
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.browse {
  cursor: pointer;
  color: #ef842d;
  padding-right: 20px;
}
.search-top-menu {
  display: flex;
  padding: 40px 0 10px 40px;
  .item {
    margin-right: 25px;
    .value {
      font-size: 18px;
    }
    .value-active {
      color: #f57f45;
      font-weight: 600;
    }
    .line {
      height: 2px;
      background-color: #f57f45;
      position: relative;
      top: 11px;
    }
  }
}
.border {
  border-top: 1px solid #ececec;
}
.status {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center !important;
  color: #fff;
  font-size: 13px;
  align-items: center;
}
.status-progress {
  background: #11828f;
}
.status-expired {
  background: #c0399f;
}
.status-completed {
  background: #925acf;
}
.search {
  display: flex;
  padding-left: 7px;
  border: none;
  .search-icon {
    position: relative;
    left: 35px;
    top: 8px;
  }
  .search-input {
    input {
      background: #f2f2f2;
      outline: none;
      border-radius: 10px;
      border: none;
      width: 280px;
      height: 40px;
      padding-left: 45px;
    }
    input:focus {
      border: 1px solid #f57f45;
      background: #fff;
    }
  }
}
.detail-menu-close {
  height: 30px !important;
}
.detail-menu {
  display: block !important;
  background: #fff;
  border-radius: 6px;
  width: 154px;
  height: 100px;
  position: relative;
  top: 40px;
  left: 15px;
  .menu-item {
    color: #25213b;
    font-size: 14px;
    padding: 10px 0 0 10px;
  }
  .menu-item:hover {
    background: #f2f0f9;
  }
  .menu-delete {
    color: #d30000;
  }
}
.loading-pic {
  margin-top: 40px;
  img {
    width: 60px;
    height: 60px;
  }
  text-align: center;
}
.add-sourcing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 15px;
  background: #f57f45;
  border-radius: 8px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* White */

  color: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.show-close {
  .content {
    height: 70px;
    margin-top: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .btn {
    display: flex;
    justify-content: right;
    .cancel {
      color: #989696;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .confirm {
      background: #f57f45;
      color: #fff;
      width: 75px;
      height: 35px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }
  }
  .loading-button {
    opacity: 0.8;
    img {
      width: 18px;
    }
  }
}
</style>
<style lang="less" scoped>
.ivu-table thead {
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
}
.ivu-table th {
  background: none;
  font-size: 18px;

  font-weight: 500;
  color: #fefefe;
  line-height: 19px;
}
.list-table {
  width: 100%;
}
.main-box {
  margin: 0 auto;
}
.main-title-box-p {
  margin: 0 auto;
  background: #3b3951;
}
</style>
